export const en = {
    banner: "For download, please Press “···” to open from browser.",
    title: "OenoBordeaux",
    description_1: "Wine training for pros",
    description_2: "Looking to surprise your clients, boost your sales, or simply perfect your wine knowledge? Then OenoBordeaux is the app for you!",
    description_3: "Convenient, fun and on-demand, OenoBordeaux provides wine expertise at your fingertips!",
    ios: "For iOS (Apple)",
    android: "For Android",
    openFromBroswer: "In order to download, please open this page from browser.",
    download: "Tap here to start download",
}

export const cn = {
    banner: "请点击“...“以便在浏览器中浏览并下载。",
    title: "掌上波尔多",
    description_1: "专业人士的葡萄酒学习应用",
    description_2: "想给您的客户惊喜，提升销售额或拓宽您在葡萄酒工艺学的知识面？掌上波尔多便是一款为您量身定做的应用！",
    description_3: "方便、有趣、灵活，掌上波尔多是您指尖的葡萄酒专家！",
    ios: "苹果iOS系统适用",
    android: "安卓Android系统适用",
    openFromBroswer: "请在浏览器中打开本页以便下载。",
    download: "点击此处开始下载",
}
