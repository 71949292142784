import React from 'react';

const Landscape = () => {
	return(
		<div className="page landscape">
			<h1>Please rotate your screen</h1>
		</div>
	)
};

export default Landscape;
