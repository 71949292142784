import React, { useState, useEffect } from 'react';
import { en, cn } from '../utils/translation';

const Home = (props) => {
	const [wechatBroswer, setWechatBroswer] = useState(false)
	const [iosDevice, setIosDevice] = useState(false)
	const [showBanner, setShowBanner] = useState(false)
	const [tips, setTips] = useState('')
	const [translation, setTranslation] = useState({})

	useEffect(() => {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera

		if (window.navigator.language.toLowerCase() === 'zh-cn') {
			setTranslation(cn)
		} else {
			setTranslation(en)
		}

		if (userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
			setWechatBroswer(true)
			if (window.navigator.language.toLowerCase() === 'zh-cn') {
				setTips(cn.openFromBroswer)
			} else {
				setTips(en.openFromBroswer)
			}
		} else {
			if (window.navigator.language.toLowerCase() === 'zh-cn') {
				setTips(cn.download)
			} else {
				setTips(en.download)
			}
		}
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			setIosDevice(true)
		}
	}, []);

	const downloadApp = () => {
		if (wechatBroswer) {
			setShowBanner(true)
		} else {
			var a = document.createElement('a')
			var event = new MouseEvent('click')

			// iOS detection from: http://stackoverflow.com/a/9039885/177710
			if (iosDevice) {
				a.href = 'https://apps.apple.com/cn/app/%E6%8E%8C%E4%B8%8A%E6%B3%A2%E5%B0%94%E5%A4%9A/id1247317863?l=en'

				a.dispatchEvent(event)
			} else {
				a.download = 'Oeono-Bordeaux-2.3.2.apk'
				a.href = 'https://files.civb.mediasia.cn/Oeono-Bordeaux-2.3.2.apk'

				a.dispatchEvent(event)
			}
		}
	}

	return (
		<div className="page">
			<div className="banner" style={{ display: showBanner ? 'block' : 'none' }}>
				<div className="text">{translation.banner}</div>
				<i className="icon icon-arrow"></i>
			</div>

			<div className="container">
				<div className="top">
					<div className="logo-container">
						<img className="logo" src="logo_Oeno.png" alt="logo" />
					</div>
					<div className="title">{translation.title}</div>
					<div className="description">{translation.description_1}</div>
					<div className="description">{translation.description_2}</div>
					<div className="description">{translation.description_3}</div>
				</div>
				<div className="bottom">
					<div className="tips">{tips}</div>
					<div className="btns">
						<button className="btn" onClick={downloadApp} disabled={!iosDevice ? true : false}>
							<i className={"icon " + (iosDevice ? "icon-ios-white" : "icon-ios-grey")}></i>
							<div className="text">{translation.ios}</div>
						</button>
						<button className="btn" onClick={downloadApp} disabled={iosDevice ? true : false}>
							<i className={"icon " + (!iosDevice ? "icon-android-white" : "icon-android-grey")}></i>
							<div className="text">{translation.android}</div>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
