import React, { useEffect } from 'react';
import Home from './pages/home';
import { isMobileSafari } from 'react-device-detect';
import Landscape from './pages/landscape';

function App() {
	useEffect(() => {
		// Account for bottom bar on iOS Safari
		if (isMobileSafari) {
			// Disable zoom on iOS Safari
			document.addEventListener('gesturestart', function (e) {
				e.preventDefault();
			});
		}
	}, []);

	return (
		<>
			<Landscape />
			<main id="main">
				<Home />
			</main>
		</>
	);
}

export default App;
